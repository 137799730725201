<template>
	<svg viewBox="0 0 653 441.2" fill="currentColor">
		<rect x="73.2" y="143.7" width="195.2" height="20"></rect>
		<rect x="73.2" y="82.7" width="195.2" height="20"></rect>
		<rect x="73.2" y="203.7" width="195.2" height="20"></rect>
		<rect x="73.2" y="263.7" width="195.2" height="20"></rect>
		<rect x="73.2" y="319" width="195.2" height="20"></rect>
		<rect x="384.7" y="143.7" width="195.2" height="20"></rect>
		<rect x="384.7" y="82.7" width="195.2" height="20"></rect>
		<rect x="384.7" y="203.7" width="195.2" height="20"></rect>
		<rect x="384.7" y="263.7" width="195.2" height="20"></rect>
		<rect x="384.7" y="319" width="195.2" height="20"></rect>
		<path d="M0-0.6v7.5v7.5v411.8v14.4v0.6h653v-5v-10V14.4V2.5v-3.1H0z M638,426.2H15V14.4h623V426.2z"></path>
	</svg>
</template>
